import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Layout from "../layouts/Layout";
//import SiteMetadata from "../components/SiteMetadata";
import Img from "gatsby-image";

function AllPrints() {
	const data = useStaticQuery(graphql`
		query {
			allFeaturedEtsyListing(
				filter: { is_digital: { eq: true } }
				limit: 20
				sort: { fields: views }
			) {
				totalCount
				nodes {
					id
					description
					price
					title
					url
					childrenEtsyListingImage {
						childFile {
							childImageSharp {
								fluid(maxWidth: 1000, maxHeight: 1000, quality: 85) {
									...GatsbyImageSharpFluid_withWebp
								}
							}
						}
					}
				}
			}
		}
	`);

	return (
		<Layout>
			<div>
				<div className=' mx-auto px-4  mt-20'>
					<div className='lg:text-center mb-12'>
						<h3 className='mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 py-2 sm:text-4xl sm:leading-10'>
							Digital Downloads Wall Art -{" "}
							{data.allFeaturedEtsyListing.totalCount}
						</h3>

						<div className='border-b-8 flex justify-start border-yellow-500  h-1 lg:mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t '></div>
						<p className='mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto'>
							Popular High Resolution Printable Wall Art for Kids Room & Man
							Caves
						</p>
					</div>

					<div className='flex flex-col-reverse justify-center md:flex-row-reverse mx-auto'>
						<div className='items-center flex flex-wrap w-4/5'>
							{data.allFeaturedEtsyListing.nodes.slice(0, 20).map((item) => (
								<div className='w-full md:w-6/12 lg:w-3/12   px-4 mb-6 group'>
									<Link to={`/${item.id}`}>
										<Img
											fluid={
												item.childrenEtsyListingImage[1].childFile
													.childImageSharp.fluid
											}
											alt='Video game poster'
											className='rounded-lg group-hover:opacity-75 transition duration-150 ease-in-out '
										/>

										<div className='text-sm text-center mt-4'>
											<p class='text-black leading-none'>
												{item.title.split(",")[0]}
											</p>
											<p class='text-grey-dark font-semibold mt-2'>
												${item.price}
											</p>
										</div>
									</Link>
								</div>
							))}
						</div>

						<div class=' w-auto px-6 flex mt-6 justify-start bg-gray-100'>
							<div class='w-12/12 pb-1 bg-white'>
								<ul class='flex sm:flex-col w-full'>
									<li class=''>
										<span class='flex font-medium text-sm text-gray-400 px-4 my-4 uppercase'>
											Filter by Category
										</span>
									</li>
									<li class='my-px'>
										<a
											href='#'
											class='flex flex-row items-center h-12 px-4 rounded-lg text-gray-600 hover:bg-gray-100'>
											<span class='flex items-center justify-center text-lg text-gray-400'>
												<svg
													fill='none'
													stroke-linecap='round'
													stroke-linejoin='round'
													stroke-width='2'
													viewBox='0 0 24 24'
													stroke='currentColor'
													class='h-6 w-6'>
													<path d='M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4'></path>
												</svg>
											</span>
											<span class='ml-3'>Video Game</span>
										</a>
									</li>
									<li class='my-px'>
										<a
											href='#'
											class='flex flex-row items-center h-12 px-4 rounded-lg text-gray-600 hover:bg-gray-100'>
											<span class='flex items-center justify-center text-lg text-gray-400'>
												<svg
													fill='none'
													stroke-linecap='round'
													stroke-linejoin='round'
													stroke-width='2'
													viewBox='0 0 24 24'
													stroke='currentColor'
													class='h-6 w-6'>
													<path d='M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01'></path>
												</svg>
											</span>
											<span class='ml-3'>Maps</span>
										</a>
									</li>
									<li class='my-px'>
										<a
											href='#'
											class='flex flex-row items-center h-12 px-4 rounded-lg text-gray-600 hover:bg-gray-100'>
											<span class='flex items-center justify-center text-lg text-gray-400'>
												<svg
													fill='none'
													stroke-linecap='round'
													stroke-linejoin='round'
													stroke-width='2'
													viewBox='0 0 24 24'
													stroke='currentColor'
													class='h-6 w-6'>
													<path d='M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z'></path>
												</svg>
											</span>
											<span class='ml-3'>Funny</span>
										</a>
									</li>

									<li class='my-px'>
										<a
											href='#'
											class='flex flex-row items-center h-12 px-4 rounded-lg text-gray-600 hover:bg-gray-100'>
											<span class='flex items-center justify-center text-lg text-gray-400'>
												<svg
													fill='none'
													stroke-linecap='round'
													stroke-linejoin='round'
													stroke-width='2'
													viewBox='0 0 24 24'
													stroke='currentColor'
													class='h-6 w-6'>
													<path d='M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z'></path>
												</svg>
											</span>
											<span class='ml-3'>Motivational Quotes</span>
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
}

export default AllPrints;
